'use strict';

Gri.module({
  name: 'link-alpha',
  ieVersion: null,
  $el: $('.link-alpha'),
  container: '.link-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $contentNews = $('.content-alpha-news');
    // endregion

    // region run
    $.ajax({
      url: '/news.json',
      success: function (data) {
        $contentNews.empty();
        let _tmp = '';
        $.each(data, function (index, val) {
          _tmp += `
            <a href="javascript:;" class="link-alpha clearfix min-margin" data-index="${index}">
              <div class="text-wrapper">
                <p>${val.name}</p>
                <div class="date-wrapper">
                  <p>${val.date}</p>
                </div>
              </div>
              <div class="icon-wrapper">
                <i class="icon-arrow-right-2"></i>
              </div>
            </a>`;
        });
        $contentNews.append(_tmp);

        let _modalTmp = '';
        $('.link-alpha').on('click', function () {
          let newsDataID = $(this).data('index');
          $('[tpl="modal-content"]').find('.content').empty();
          _modalTmp = ` <h3>${data[newsDataID].name}</h3>
                <h5>${data[newsDataID].date}</h5>
                <p>${data[newsDataID].desc}</p>`;
          $('[tpl="modal-content"]').find('.content').append(_modalTmp);
          $(window).trigger('modal-content');
        });
      },
      error: function () {
        console.log('News list not found!');
      }
    });
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion
    
  }
});
